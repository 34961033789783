import React from "react";

// const ShowModal = ({movie}) => {
//     return (
//         <div className="modal">
//             <div className="modal-container">
//                     <img src= {movie.Postal !== 'N/A' ? movie.Postal : 'https://via.placeholder.com/400'} alt={movie.Title}/>

//                 <div className="modal-details">
//                     <h1>{movie.Title}</h1>
//                     <span>{movie.Year}</span>
//                     <p>{movie.Runtime}</p>
//                     <p>{movie.Plot}</p>
//                 </div>
//             </div>
//         </div>
//     );
// };


const ShowModal = ({ movie, onClose }) => {
    return (
      <div className="modal">
        <div className="modal-container">
          {/* Render movie details based on movie object */}
          <img src={movie.Poster !== "N/A" ? movie.Poster : "https://via.placeholder.com/400"} alt={movie.Title} />
          <div className="modal-details">
            <h1>{movie.Title}</h1>
            <span>{movie.Year}</span>
            <p className="runtime">{movie.Runtime}</p>
            <p className="plot">{movie.Plot}</p>
            
            <span className="exit" onClick={onClose}>X</span>
          </div>
        </div>
      </div>
    );
  };
  

export default ShowModal