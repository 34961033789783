import {useEffect, useState} from "react";

import MovieCard from "./MovieCard";
import ShowModal from "./ShowModal";

import './App.css';
import searchIcon from './search.svg';

const API_URL = 'https://www.omdbapi.com?apikey=8a8a73e1'

const movie1 = {
        "Title": "Lift",
        "Year": "2024",
        "imdbID": "tt14371878",
        "Type": "movie",
        "Poster": "https://m.media-amazon.com/images/M/MV5BNTBlNmEwNzQtZTc5MC00YmVjLTk5NjgtMmM0NDFmZjJkZjYzXkEyXkFqcGdeQXVyNTE1NjY5Mg@@._V1_SX300.jpg"
}

const App = () => {

    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedMovie, setSelectedMovie] = useState(null);


    const searchMovies = async (title) => {
        const response = await fetch(`${API_URL}&s=${title}`);
        const data = await response.json();

        setMovies(data.Search);
    }

    const handleMovieClick = async (clickedMovie) => {
        const response = await fetch(`${API_URL}&i=${clickedMovie.imdbID}`);
        const detailedMovie = await response.json();

       setSelectedMovie(detailedMovie);
       setShowModal(true);
    }

    useEffect(() => {
        searchMovies('')
    }, []);

      

    return (
        <div className="app">
            <h1>FrameRate</h1>

            <div className="search">
                <input
                    placeholder="search for movies"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img
                    src={searchIcon}
                    alt="search"
                    onClick={() => searchMovies(searchTerm)}
                />
            </div>

            {movies?.length > 0 ? (
                <div className="container">
                    {movies.map((movie) => (
                    <MovieCard movie={movie} onMovieClick={(clickedMovie) => handleMovieClick(clickedMovie)}></MovieCard>
                    ))}
                </div>
                ) : (
                    <div className="empty">
                        <h2>No movies found</h2>
                    </div>
            )}

            {showModal && <ShowModal movie={selectedMovie} onClose={() => setShowModal(false)}/>}
        </div>

    );
};

export default App;